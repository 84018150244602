import {setFieldEdition, setFieldVisibility} from "../../../../../apps/KpModule/actions"

export const module_ = {
    object: 'Adhesion',
    name: 'Application',
    tKey: 'mTitle_adhesionApplication',
    newable: false,
    removable: true,
    objectIdentifier: 'mail',
    category: {
        path: 'Adhésion',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: "Référence"},
            {path: 'mail'},
            {path: 'firstname'},
            {path: 'lastname'},
            {path: 'phone'},
            {path: 'organizationName'},
            {path: 'status', type: "status", fieldPath: ['id', 'name', 'style'], width: 450},
            {path: 'createdAt', tKey: 'creationDate'},
        ],
        form: {
            fields: [
                {path: 'userSection', section: "administrativeSection", data: 'Président(e)', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}},

                {path: 'civility', section: "administrativeSection", disabled: true},
                {path: 'firstname', section: "administrativeSection", disabled: true},
                {path: 'lastname', section: "administrativeSection", disabled: true},
                {path: 'mail', section: "administrativeSection", disabled: true},
                {path: 'phone', section: "administrativeSection", type: 'phoneNumber', disabled: true},

                {path: 'organizationSection', section: "administrativeSection", data: 'Association', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {path: 'organizationName', tKey: 'name', disabled: true, section: "administrativeSection"},
                {path: 'organizationAddress', tKey: 'address', disabled: true, section: "administrativeSection"},
                {path: 'organizationAdditionalAddress', tKey: 'additionalAddress', disabled: true, section: "administrativeSection"},
                {path: 'organizationZipCode', tKey: 'zipCode', disabled: true, section: "administrativeSection"},
                {path: 'organizationCity', tKey: 'city', disabled: true, section: "administrativeSection"},
                {path: 'organizationCountry', tKey: 'country', disabled: true, section: "administrativeSection"},
                {path: 'organizationSize', tKey: "Taille de l'association", section: "administrativeSection", disabled: true, sortList: false},
                {path: 'rna', tKey: 'N° Registre National des Associations', disabled: true, section: "administrativeSection"},
                {path: "sheltersNumber", tKey: 'Nombre de refuges', disabled: true, section: "administrativeSection"},
                {path: 'generalInterestRecognition', disabled: true, section: "administrativeSection"},
                {path: 'publicUtilityRecognition', disabled: true, section: "administrativeSection"},
                {path: 'hasEndowmentFund', disabled: true, section: "administrativeSection"},
                {path: 'hasDUERP', disabled: true, section: "administrativeSection"},
                {path: 'DUERPUpdateDate', disabled: true, section: "administrativeSection"},


                {path: 'documentsSection', data: 'Documents', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true, section: "administrativeSection"},

                {path: 'contactID', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", disabled: true, section: "administrativeSection"},
                {path: 'situationNotice', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", disabled: true, section: "administrativeSection"},
                {path: 'updatedStatus', type: "simpleFiles", fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], disabled: true, section: "administrativeSection"},
                {path: 'balanceSheetsAndIncomeStatements', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", disabled: true, section: "administrativeSection"},
                {path: 'officialStatement', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", disabled: true, section: "administrativeSection"},
                {path: 'CERFA', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", disabled: true, section: "administrativeSection"},
                {path: 'DDPVisitReports', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", disabled: true, section: "administrativeSection"},
                {path: 'lastGeneralMeeting', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", disabled: true, section: "administrativeSection"},

                {path: 'structureSection', section: "technicalSection", data: 'Structure', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {path: 'employeesNumber', section: "technicalSection", tKey: 'employeesNumber_2', disabled: true},
                {path: 'electedOfficialsNumber', section: "technicalSection", disabled: true},
                {path: 'volunteersNumber', section: "technicalSection", tKey: 'volunteersNumber_2', disabled: true},
                {path: 'investigatorsNumber', section: "technicalSection", tKey: 'investigatorsNumber_2', disabled: true},

                {path: 'animalTypes', section: "technicalSection", tKey: 'animalTypes_2', disabled: true},
                {
                    path: 'adhesionCapacityByAnimalType', section: "technicalSection",
                    disabled: true,
                    type: 'dtObjects',
                    fields: [
                        'animalType',
                        {path: 'number', type: 'editText', width: 100},
                    ]
                },

                {path: 'activitiesSection', section: "technicalSection", data: 'Activités', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {
                    path: 'adhesionCapacityByActivity', section: "technicalSection",
                    type: 'dtObjects',
                    disabled: true,
                    defaultSortBy: 'activity',
                    defaultSortDirection: 'ASC',
                    fields: [
                        'activity',
                        'animalType',
                        {path: 'number', type: 'editText', width: 100},
                    ]
                },

                {path: 'SheltersSection', section: "technicalSection", data: 'Refuges', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {
                    path: 'adhesionShelters',
                    section: "technicalSection",
                    tKey: "Coordonnées",
                    disabled: true,
                    //defaultSortBy: 'activity',
                    //defaultSortDirection: 'ASC',
                    viewMap: {
                        dt: [
                            'name',
                            'address',
                            'zipCode',
                            'city',
                        ],
                        form: [
                            'name',
                            'address',
                            'additionalAddress',
                            'zipCode',
                            'city',
                            'mail',
                            {path: 'phone', type: 'phoneNumber'},
                        ]
                    }
                },

                {path: 'documentsSection2', section: "technicalSection", data: 'Documents', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {path: 'documents', disabled: true, section: "technicalSection", tKey: 'Documents divers'},


                {path: 'visitors', display: "fullName", tKey: "Visiteurs", fieldPath: ['id', 'fullName', 'completeInfo'], filters: ['isActive', 'eligibleToVisit'], required:true},
                {path: 'visitReport', tKey: 'Rapport de visite', fieldPath: ['id', 'filename', 'date', 'user.id', 'user.name'], type: "simpleFiles", required: true},

                {path: 'paymentSection', section: "paymentSection", data: 'Paiement', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {path: 'paymentDate', tKey: 'dateOfReceipt', section: "paymentSection", required: true},

                {path: 'followUpSection', data: 'Suivi demande', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}, disabled: true},

                {path: 'comments', tKeyText: "Suivi"},
                {path: 'applicationNumber', hidden: true},
                {path: 'status', hidden: true},
                {path: 'buttons', hidden: true}
            ],
            onOpen: ({ store, module }) => {
                const state = store.getState()
                const statusId = _.get(state, 'edit.object.data.status.id')
                const administrativeFields = module.viewMap.form.fields.filter(field => field.section === "administrativeSection")
                const technicalFields = module.viewMap.form.fields.filter(field => field.section === "technicalSection")

                const visitorsField = module.viewMap.form.fields.find(field => field.path === "visitors" )
                const visitReportField = module.viewMap.form.fields.find(field => field.path === "visitReport" )
                const organizationSizeField = module.viewMap.form.fields.find(field => field.path === "organizationSize" )
                const paymentDateField = module.viewMap.form.fields.find(field => field.path === "paymentDate" )
                const paymentSectionField = module.viewMap.form.fields.find(field => field.path === "paymentSection" )

                administrativeFields.forEach(field => store.dispatch(setFieldVisibility(field.id, ['filed', 'incomplete', 'updated', 'preValidated', 'updated2', 'filed2', 'adjourned', 'adjournedBD', 'completed', 'visitInProgress', 'visitValidated', 'visitRejected', 'accepted', 'validated'].includes(statusId))))
                technicalFields.forEach(field => store.dispatch(setFieldVisibility(field.id, ['updated2', 'filed2', 'adjourned', 'adjournedBD', 'completed', 'visitInProgress', 'visitValidated', 'visitRejected', 'accepted', 'validated'].includes(statusId))))

                store.dispatch(setFieldVisibility(visitorsField.id,  ["completed", "visitInProgress", "visitValidated", "visitRejected", "accepted", 'validated'].includes(statusId)))
                store.dispatch(setFieldEdition(visitorsField.id,  "completed" === statusId))

                store.dispatch(setFieldVisibility(visitReportField.id,  ["visitInProgress", "visitValidated", "visitRejected", "accepted", 'validated'].includes(statusId)))
                store.dispatch(setFieldEdition(visitReportField.id,  "visitInProgress" === statusId))

                store.dispatch(setFieldEdition(organizationSizeField.id,  ['filed', 'updated', 'filed2', 'updated2', 'completed', 'visitInProgress', "visitValidated", "visitRejected"].includes(statusId)))


                store.dispatch(setFieldVisibility(paymentDateField.id,  ["accepted", 'validated'].includes(statusId)))
                store.dispatch(setFieldVisibility(paymentSectionField.id,  ["accepted", 'validated'].includes(statusId)))
                store.dispatch(setFieldEdition(paymentDateField.id,  ["accepted"].includes(statusId)))


            }
        }
    }
}
