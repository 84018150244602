import React, {useState, useEffect, useRef, useLayoutEffect, useCallback} from 'react';
import {
    ArrowLeft,
    ArrowRight,
    ChevronDown,
    ChevronLeft,
    ChevronsDown, ChevronUp,
    CornerDownLeft, Download, FilePlus, File, Maximize2,
    MessageCircle, Minimize2, Minus, Plus,
    Send,
    X, Paperclip
} from 'react-feather';
import './conversation2.css';
import { ObjectID } from 'bson'
import axios from "axios";
import {ProgressBar} from "react-bootstrap";
import {toastr} from "react-redux-toastr";
import {getFile} from "../utils/network";
import moment from "moment/moment";
import _ from "lodash";


const Conversation2 = ({
                           messages: initialMessages = [],
                           currentUser,
                           onSendMessage,
                           placeholder = "typeAMessage",
                           maxHeight = "440px",
                           minHeight = "60px",
                           t,
                           language,
                           groupModel,
                           push,
                           url
                       }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [replyMessage, setReplyMessage] = useState(null);
    const [filesToUpload, setFilesToUpload] = useState([]); // Track selected files
    const [messageDiscussion, setMessageDiscussion] = useState(null); // State for toggling views //showDiscussion setShowDiscussion
    const [activeMessageIndex, setActiveMessageIndex] = useState(0);
    const [highlightedMessageId, setHighlightedMessageId] = useState(null);
    const messagesEndRef = useRef(null);
    const specificMessageRef = useRef(null); // Ref for the specific message
    const [lastViewedMessageId, setLastViewedMessageId] = useState(null);  // Store message ID instead of DOM reference
    const shouldScrollRef = useRef(false);  // Flag to control when to scroll
    const [isMinimized, setIsMinimized] = useState(false); // Minimize state
    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [fileUploadError, setFileUploadError] = useState(null);
    const [initialUnseenMessages, setInitialUnseenMessages] = useState([]);

    const firstNonSeenMainMessage = initialMessages.find(msg => !msg.seenBy.includes(currentUser.id))

    // Initialize messages with initialMessages prop
    useEffect(() => {
        setMessages(initialMessages);
    }, [initialMessages]);

    //when first entering the component, we scroll into the first non seen msg, else we scroll to the end of the conversation
    useLayoutEffect(() => {
        const isInitialState = messages.length === initialMessages.length
        if (isInitialState && firstNonSeenMainMessage) {
            //scroll to first non seen messages
            const element = document.getElementById(`message-${firstNonSeenMainMessage.id}`);
            if (element) element.scrollIntoView({behavior: 'auto', block: 'center'})

            //try this if the scroll propagated outside of the convo component
            /*
            const container = document.querySelector('.conversation-messages');
            const element = document.getElementById(`message-${firstNonSeenMainMessage.id}`);
            if (element && container) {
                const containerRect = container.getBoundingClientRect();
                const elementRect = element.getBoundingClientRect();

                // Calculate the offset to center the element
                const containerHeight = containerRect.height;
                const elementOffset = elementRect.top - containerRect.top; // Offset relative to the container
                const elementHeight = elementRect.height;

                const offset = elementOffset - (containerHeight / 2) + (elementHeight / 2);

                // Apply the scroll
                container.scrollTop += offset;
            }
             */
        } else {
            //scroll to the end of convo
            const containerName = !messageDiscussion ?  '.conversation-messages' : '.conversation-subMessages'
            const container = document.querySelector(containerName);
            if (container) {
                container.scrollTo({
                    top: container.scrollHeight, // Scroll to the bottom
                    behavior: isInitialState ? 'auto' : 'smooth',          // Enable smooth scrolling
                })
            }

            messages.forEach(msg => {
                if( !msg.seenBy.includes(currentUser.id)){
                    msg.seenBy.push(currentUser.id)
                }
            })
        }
    }, [messages]);


    /*
    useEffect(() => {
        if (messagesEndRef.current) {
            // Always scroll to the last message on initial load or when messages change
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [messages]);


    useEffect(() => {
        // Scroll immediately on component mount
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, []);

     */



    // Scroll to the bottom whenever messages are updated
    /*useEffect(() => {
        if (messagesEndRef.current) {
            const isAtBottom = messagesEndRef.current.getBoundingClientRect().top <= window.innerHeight;
            if (isAtBottom) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [messages]);*/

    // Memoize updateSeenBy to prevent unnecessary re-renders
    /*
    const updateSeenBy = useCallback((user) => {
        console.log('ok')
        setMessages((prevMessages) => {
            let hasChanges = false;
            const updatedMessages = prevMessages.map((msg) => {
                if (!msg.seenBy.includes(user.id)) {
                    hasChanges = true;
                    return { ...msg, seenBy: [...msg.seenBy, user.id] };
                }
                // Return a new object even if not changed
                return { ...msg };
            });
            console.log('ok', hasChanges, updatedMessages)
            return hasChanges ? updatedMessages : prevMessages;
        });
    }, []);

     */


    /*useEffect(() => {
        const container = document.querySelector('.conversation-messages');
        if (container) {
            //updateSeenBy(currentUser);
            // Scroll to the bottom of the container
            //scrollToAMessage(messages[messages.length-5]?.id)
            container.scrollTo({
                top: container.scrollHeight, // Scroll to the bottom
                behavior: 'smooth',          // Enable smooth scrolling
            })


        }
    }, [messages]);

     */



    // Function to scroll to the last message
    const scrollToLastMessage = () => {
        const containerName = !messageDiscussion ?  '.conversation-messages' : '.conversation-subMessages'
        const container = document.querySelector(containerName);
        if (container) {
            container.scrollTo({
                top: container.scrollHeight, // Scroll to the bottom
                behavior: 'smooth',          // Enable smooth scrolling
            })
        }
    };

    const handleSend = () => {
        if (newMessage.trim()) {
            const OID = new ObjectID()
            const newMessageObject = {
                id: OID.toString(),
                text: newMessage,
                sender: currentUser,
                responseTo: replyMessage,
                parentMessage: messageDiscussion,
                seenBy: [currentUser.id],
                files: filesToUpload,
                timestamp: new Date().toISOString(),
            };
            setMessages([...messages, newMessageObject]);
            onSendMessage(newMessageObject); // Call the onSendMessage action
            setNewMessage('');
            setReplyMessage(null); // Clear the reply message after sending
            setFilesToUpload([])
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    /*
    const handleResponse = (data) => {
        if (data) {
            const userObject = {currentUser}
            push({
                ...data,
                ...userObject,
                date: moment().format('YYYY-MM-DD HH:mm')
            })
        }
        else {
            toastr.error('errorUploading')
        }
    }
     */

    const handleFileUpload = async (event) => {
        let filesToUploadPrep = []
        const files = Array.from(event.target.files); // Get all selected files
        if (files.length === 0) return;

        // Allow to re-upload the same file in a row
        event.target.value = null

        setFileUploadError(null);
        setFileUploadProgress(0);

        for (const file of files) {
            const data = new FormData();
            const fileName = `${moment().format('YYYY-MM-DD_HH:mm:ss')}_${file.name}`
            const fileType = file.type
            const fileSize = file.size
            const chunkSize = 15 * 1024 * 1024 // 15MB
            let fileChunk = null, chunkNb = 0

            for (let start = 0; start < fileSize; start += chunkSize, chunkNb++) {
                if (start + chunkSize > fileSize) {
                    fileChunk = file.slice(start, fileSize)
                }
                else {
                    fileChunk = file.slice(start, start + chunkSize)
                }
                data.append('fileChunks', fileChunk, fileName)
            }

            data.append('fileType', fileType)
            data.append('fileSize', fileSize)
            data.append('chunkNb', chunkNb)

            const options = {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (progressEvent) => {
                    const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setFileUploadProgress(percent);
                },
            };
            try {
                const response = await axios.post(`/${url}`, data, options);
                const fileMessage = {
                    id: response.data.id,
                    name: response.data.filename,
                    originalName: file.name,
                    size: fileSize
                };
                filesToUploadPrep.push(fileMessage)
                //setMessages((prevMessages) => [...prevMessages, fileMessage]);
                toastr.success(`${file.name} uploaded successfully`);
            } catch (err) {
                const message = _.get(err, 'response.data');
                console.error(err);
                toastr.error(message || 'Error uploading file');
                setFileUploadError(message || 'Error uploading file. Please try again.');
            }
        }

        setFileUploadProgress(0);
        setFilesToUpload((prevFiles) => [...prevFiles, ...filesToUploadPrep]); // Append the new file
    };

    const handleDownload = (id, fileName) => {
        //password is undefined
        toastr.info('downloading')
        getFile({
            fileId: id,
            filename: fileName,
            password: undefined,
            language,
            groupModel
        })
    }

    const detachFile = (file) => {
        setFilesToUpload((prevFiles) => prevFiles.filter((fil) => fil.id !== file.id));
    }

    const handleReply = (replyMessage) => {
        setReplyMessage(replyMessage); // Set the message text being replied to
    };

    const handleStartDiscussion = (msg) => {
        setMessageDiscussion(msg)
        setReplyMessage(null);
        // Save current scroll position
        setLastViewedMessageId(msg.id);  // Store the ID of the message
    }

    const handleBackFromDiscussion = () => {
        setMessageDiscussion(null);
        setReplyMessage(null);
        shouldScrollRef.current = true;  // Set flag to true before state updates
    }

    const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    // Single effect to handle scrolling when returning from discussion
    useLayoutEffect(() => {
        if (shouldScrollRef.current && !messageDiscussion && lastViewedMessageId) {
            const element = document.getElementById(`message-${lastViewedMessageId}`);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
                shouldScrollRef.current = false;  // Reset the flag
            }
        }
    }, [messageDiscussion, lastViewedMessageId]);

    // Single effect to handle scrolling when returning from discussion
    const scrollToAMessage = (msgID) => {
        if (msgID) {
            const element = document.getElementById(`message-${msgID}`);
            if (element) {
                // Highlight the message
                setHighlightedMessageId(msgID);

                element.scrollIntoView({
                    behavior: 'auto',
                    block: 'nearest', // Align to the center of the view for better visibility
                });

                setTimeout(() => {
                    setHighlightedMessageId(null);
                }, 500);
            }
        }
    };

    const getSortedUniqueParentMessages = (messages) => {
        const uniqueParentMessages = _.uniqBy(
            messages.map((msg) => msg.parentMessage).filter((parentMsg) => parentMsg !== null),
            'id'
        );

        return _.sortBy(uniqueParentMessages, (parentMsg) => new Date(parentMsg.timestamp)).reverse()
    };

    const sortedUniqueParentMessages = getSortedUniqueParentMessages(messages);

    const navigateToPreviousMessage = () => {
        if (sortedUniqueParentMessages.length > 0){
            const lastActiveMessageIndex = sortedUniqueParentMessages.length - 1
            if (activeMessageIndex < lastActiveMessageIndex) {
                const previousIndex = activeMessageIndex + 1;
                setActiveMessageIndex(previousIndex);
                scrollToAMessage(sortedUniqueParentMessages[previousIndex]?.id);
            }
            else { // activeMessageIndex = lastActiveMessageIndex
                setActiveMessageIndex(activeMessageIndex);
                scrollToAMessage(sortedUniqueParentMessages[activeMessageIndex]?.id);
            }
        }
    };

    const navigateToNextMessage = () => {
        if (sortedUniqueParentMessages.length > 0){
            if (activeMessageIndex > 0) {
                const nextIndex = activeMessageIndex - 1;
                setActiveMessageIndex(nextIndex);
                scrollToAMessage(sortedUniqueParentMessages[nextIndex]?.id);
            }
            else { // activeMessageIndex = 0
                setActiveMessageIndex(activeMessageIndex);
                scrollToAMessage(sortedUniqueParentMessages[activeMessageIndex]?.id);
            }
        }
    };



    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const mainMessages = messages.filter(msg => !msg.parentMessage)

    const mainMessagesGroupedByDay = _.groupBy(mainMessages, (message) => {
        const date = new Date(message.timestamp);
        const formattedDate = new Intl.DateTimeFormat('fr-FR', {
            weekday: 'short', // Abbreviated weekday (e.g., lun)
            day: '2-digit',   // Day in two digits
            month: '2-digit', // Month in two digits
            year: 'numeric'   // Full year
        }).format(date);

        // Capitalize the first letter
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    });

    const subMessagesOfAMainMessage = !messageDiscussion ? [] : messages.filter(msg => msg.parentMessage?.id === messageDiscussion.id || msg.id === messageDiscussion.id)
    const subMessagesGroupedByDay = _.groupBy(subMessagesOfAMainMessage, (message) => {
        const date = new Date(message.timestamp);
        const formattedDate = new Intl.DateTimeFormat('fr-FR', {
            weekday: 'short', // Abbreviated weekday (e.g., lun)
            day: '2-digit',   // Day in two digits
            month: '2-digit', // Month in two digits
            year: 'numeric'   // Full year
        }).format(date);

        // Capitalize the first letter
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    });



    return (
        <div className="conversation-container">
            {!messageDiscussion ? (
                <>
                    <div
                        className="conversation-messages"
                        style={{
                            maxHeight: maxHeight,
                            height: isMinimized ? minHeight : maxHeight,
                            transition: 'height 0.5s ease', // Smooth height transition
                            willChange: 'height', // Add this
                        }}
                    >
                        {mainMessages.length === 0 ? (
                                <div className="no-messages-placeholder">
                                    <p
                                        className="no-messages-placeholder"
                                    >
                                        Démarrer la discussion...
                                    </p>
                                </div>
                            ) :
                            (
                                Object.keys(mainMessagesGroupedByDay).map((date) => (
                                    <div key={date} className="grouped-messages">
                                        {/* Date Header */}
                                        <div className="message-date">{date}</div>
                                        {/* Messages */}
                                        {mainMessagesGroupedByDay[date].map((message) => (
                                            <>
                                                {firstNonSeenMainMessage?.id === message.id && (<div className="nonSeenMessagesBadge">
                                                    <span style={{display: "inlineBlock"}}>Nouveaux messages</span>
                                                </div>)}
                                                <div
                                                    key={message.id}
                                                    id={`message-${message.id}`}
                                                    className={`message-container ${message.sender.id === currentUser.id ? 'sent' : 'received'} ${
                                                        highlightedMessageId === message.id ? 'highlighted' : ''
                                                    }`}
                                                >
                                                    <div
                                                        className={`message-bubble ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}
                                                    >
                                                        <div className="message-header">
                                                            <span className="sender-name">{message.sender.name}</span>
                                                            <CornerDownLeft
                                                                className="replyIcon"
                                                                size={18}
                                                                onClick={() => handleReply(message)}
                                                            />
                                                        </div>
                                                        {message.responseTo && (
                                                            <div
                                                                className={`replyMessage-header ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}
                                                                onClick={() => scrollToAMessage(message.responseTo.id)}
                                                            >
                                                            <span
                                                                className="reply-sender-name">{message.responseTo.sender.name}</span>
                                                                <p className="message-text">{message.responseTo.text}</p>
                                                            </div>
                                                        )}
                                                        <p className="message-text">{message.text}</p>
                                                        {!!message.files.length && (
                                                            <div className="messageFilesWrapper">
                                                                {message.files.map((file) => (
                                                                    <a
                                                                        download={message.text}
                                                                        title={file.originalName}
                                                                        style={{cursor: "pointer", color: "black"}}
                                                                        className="messageFile"
                                                                        onClick={() => handleDownload(file.id, file.name)}
                                                                    >
                                                                        <Download size={18} style={{flexShrink: 0}}/>
                                                                        <span
                                                                            className="fileName">{file.originalName}</span>
                                                                        <span
                                                                            className="fileSize">{(file.size / 1024).toFixed(2)} KB</span>
                                                                    </a>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <span
                                                            className="message-time">{formatTime(message.timestamp)}</span>
                                                        <button
                                                            onClick={() => handleStartDiscussion(message)}
                                                            className="leaveCommentButton"
                                                        >
                                                            <div className="icon-with-badge">
                                                                <MessageCircle className="icon-left" size={18}/>
                                                                {
                                                                    messages.filter(msg => msg.parentMessage?.id === message.id || msg.id === message.id).length - 1 > 0 &&
                                                                    (<span className="badge">
                                                                    {messages.filter(msg => msg.parentMessage?.id === message.id || msg.id === message.id).length - 1}
                                                                </span>)
                                                                }
                                                            </div>
                                                            <span className="button-text">Ouvrir la discussion</span>
                                                            <ArrowRight className="icon-right" size={18}/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                ))
                            )
                        }
                    </div>
                    <button className="minimizeButton" title={isMinimized ? "Déplier" : "Replier"}
                            onClick={toggleMinimize}>
                        {isMinimized ? <Maximize2 size={20} style={{flexShrink: 0}}/> :
                            <Minimize2 size={20} style={{flexShrink: 0}}/>}
                    </button>
                    {!isMinimized && [
                        sortedUniqueParentMessages.length > 0 && <div className="test">
                            <button className="goUpButton" title="Naviguer vers la discussion active précedente"
                                    onClick={navigateToPreviousMessage}>
                                <ChevronUp size={22} style={{flexShrink: 0}}/>
                            </button>
                            <div className="activeMessagesNavigationBadge">
                                {`${sortedUniqueParentMessages.length - activeMessageIndex}/${sortedUniqueParentMessages.length}`}
                            </div>
                            < button className="goDownButton" title="Naviguer vers la discussion active suivante"
                                     onClick={navigateToNextMessage}>
                                <ChevronDown size={22} style={{flexShrink: 0}}/>
                            </button>
                        </div>,
                        <button className="goToTheEndButton" title="Naviguer vers la fin de la discussion"
                                onClick={scrollToLastMessage}>
                            <ChevronsDown size={24} style={{flexShrink: 0}}/>
                        </button>
                    ]}
                    {/* Reply Preview Above Input */}
                    <div className="message-input-container">
                        {!!filesToUpload.length && (
                            <div className="filesUploadPreview">
                                <File className="FileIcon" size={18} style={{cursor: "default", flexShrink: 0}}/>
                                <div className="file-items-wrapper">
                                    {filesToUpload.map((file, index) => (
                                        <div key={index} className="file-item">
                                            <span className="file-name">{file.originalName}</span>
                                            <span className="file-size">{(file.size / 1024).toFixed(2)} KB</span>
                                            <X className="deleteFileIcon" size={18} style={{flexShrink: 0}}
                                               onClick={() => detachFile(file)}/>
                                        </div>
                                    ))}
                                </div>
                                {/* Clear reply on click */}
                            </div>
                        )}
                        {replyMessage && (
                            <div className="reply-preview">
                                <button className="replyIcon2"><CornerDownLeft size={18}
                                                                               style={{cursor: "default"}}/>
                                </button>
                                <div
                                    className={`replyMessage-header sent`}
                                >
                                    <span className="reply-sender-name">{replyMessage.sender.name}</span>
                                    <p className="message-text">{replyMessage.text}</p>
                                </div>
                                <button className="cancelReplyButton" onClick={() => setReplyMessage(null)}>
                                    <X size={18}/>
                                </button>
                                {/* Clear reply on click */}
                            </div>
                        )}
                        <div className="rawInput">
                            <input
                                type="file"
                                id="fileInput"
                                encType='multipart/form-data'
                                style={{display: 'none'}}
                                multiple
                                onChange={handleFileUpload}
                                //onChange={event => this.handleFileUpload(event, t)}
                            />
                            <button
                                className="attachFile-button"
                                onClick={() => document.getElementById('fileInput').click()}
                            >
                                <Paperclip size={18}/>
                            </button>
                            <textarea
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyPress={handleKeyPress}
                                placeholder={t(placeholder)}
                                className="message-input"
                            />
                            <button
                                onClick={handleSend}
                                disabled={!newMessage.trim() && !filesToUpload.length}
                                className="send-button"
                            >
                            <Send size={18}/>
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                /* New Discussion View */

                <>
                    <div className="backButtonContainer">
                    <button
                            onClick={() => handleBackFromDiscussion()}
                            className={`back-button expanding`}
                            >
                                <ChevronLeft className="icon-left" size={30} style={{flexShrink: 0, marginLeft: "-4px"}}/>
                        </button>
                    </div>
                    <button className="minimizeButton" title={isMinimized ? "Déplier" : "Replier"} onClick={toggleMinimize}>
                        {isMinimized ? <Maximize2 size={20} style={{flexShrink: 0}}/> : <Minimize2 size={20} style={{flexShrink: 0}}/>}
                    </button>
                    {!isMinimized &&
                        <button className="goToTheEndButton" title="Naviguer vers la fin de la discussion" onClick={scrollToLastMessage}>
                            <ChevronsDown size={24} style={{flexShrink: 0}}/>
                        </button>
                    }
                    <div className="conversation-subMessages"
                         style={{
                             maxHeight: maxHeight,
                             height: isMinimized ? minHeight : maxHeight,
                             transition: 'height 0.1s ease', // Smooth height transition
                             willChange: 'height', // Add this
                         }}
                    >
                    {
                            Object.keys(subMessagesGroupedByDay).map((date) => (
                                <div key={date} className="grouped-messages">
                                    {/* Date Header */}
                                    <div className="message-date"><span>{date}</span></div>
                                    {/* Messages */}
                                    {subMessagesGroupedByDay[date].map((message) => (
                                        <div
                                            key={message.id}
                                            className={`message-container ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}
                                        >
                                            <div
                                                className={`message-bubble ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}>
                                                <div className="message-header">
                                                    <span className="sender-name">{message.sender.name}</span>
                                                    <CornerDownLeft
                                                        className="replyIcon"
                                                        size={18}
                                                        onClick={() => handleReply(message)} // Set the reply message text
                                                    />
                                                </div>
                                                {message.responseTo && (
                                                    <div
                                                        className={`replyMessage-header ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}>
                                            <span
                                                className="reply-sender-name">{message.responseTo.sender.name}</span>
                                                        <p className="message-text">
                                                            {message.responseTo.text}
                                                        </p>
                                                    </div>
                                                )}
                                                <p className="message-text">{message.text}</p>
                                                {!!message.files.length && (
                                                    <div className="messageFilesWrapper">
                                                        {message.files.map((file) => (
                                                            <a
                                                                download={message.text}
                                                                title={file.originalName}
                                                                style={{cursor: "pointer", color: "black"}}
                                                                className="messageFile"
                                                                onClick={() => handleDownload(file.id, file.name)}
                                                            >
                                                                <Download size={18} style={{flexShrink: 0}}/>
                                                                <span className="fileName">{file.originalName}</span>
                                                                <span className="fileSize">{(file.size / 1024).toFixed(2)} KB</span>
                                                            </a>
                                                        ))}
                                                    </div>
                                                )}
                                                <span className="message-time">{formatTime(message.timestamp)}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                    {/* Reply Preview Above Input */}
                    <div className="message-input-container">
                        {!!filesToUpload.length && (
                            <div className="filesUploadPreview">
                                <File className="FileIcon" size={18} style={{cursor: "default", flexShrink: 0}}/>
                                <div className="file-items-wrapper">
                                    {filesToUpload.map((file, index) => (
                                        <div key={index} className="file-item">
                                            <span className="file-name">{file.originalName}</span>
                                            <span className="file-size">{(file.size / 1024).toFixed(2)} KB</span>
                                            <X className="deleteFileIcon" size={18} style={{flexShrink: 0}} onClick={() => detachFile(file)}/>
                                        </div>
                                    ))}
                                </div>
                                {/* Clear reply on click */}
                            </div>
                        )}
                        {replyMessage && (
                            <div className="reply-preview">
                                <button className="replyIcon2">
                                    <CornerDownLeft size={18} style={{cursor: "default"}}/>
                                </button>
                                <div
                                    className={`replyMessage-header sent`}
                                >
                                    <span className="reply-sender-name">{replyMessage.sender.name}</span>
                                    <p className="message-text">{replyMessage.text}</p>
                                </div>
                                <button className="cancelReplyButton" onClick={() => setReplyMessage(null)}>
                                    <X size={18}/>
                                </button>
                                {/* Clear reply on click */}
                            </div>
                        )}
                        <div className="rawInput">
                            <input
                                type="file"
                                id="fileInput"
                                encType='multipart/form-data'
                                style={{display: 'none'}}
                                multiple
                                onChange={handleFileUpload}
                                //onChange={event => this.handleFileUpload(event, t)}
                            />
                            <button
                                className="attachFile-button"
                                onClick={() => document.getElementById('fileInput').click()}
                            >
                                <Paperclip size={18}/>
                            </button>
                            <textarea
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyPress={handleKeyPress}
                                placeholder={t(placeholder)}
                                className="message-input"
                                rows={2}
                            />
                            <button
                                onClick={handleSend}
                                disabled={!newMessage.trim() && !filesToUpload.length}
                                className="send-button"
                            >
                                <Send size={18}/>
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Conversation2;